<template>
	<div>
		<Header title="参与分账用户列表（全局）" @back="onBack"></Header>
		<a-spin :spinning="loading">
			<h4 style="margin-top: 10px;padding-left: 20px;border-left: solid 3px #169bd5;margin-bottom: 20px;font-size: 16px;">
				分账固定金额设置</h4>
			<a-row>
				<a-col style="width: 100px;">
					<a-button type="primary" v-permission="['ledger_user_list_scale_add']" @click="onAdd(2)">新增用户</a-button>
				</a-col>
				<a-col style="padding-top: 4px;color: red;">注：分账固定金额仅用于影票订单与影票售后单；商城订单、卖品订单、扫码支付单与购买分销身份不参与分账固定金额分账。</a-col>
			</a-row>
			<div>
				<a-table :columns="amountColumns" :dataSource="amountList" :pagination="false" rowKey="id">
					<template #headerCell="{ column }">
						<template v-if="column.key === 'sort'">
							排序
							<a-tooltip>
								<template #title>
									数字越大，排序越前，优先进行分账，不允许同级
								</template>
								<Icon icon="QuestionCircleOutlined"></Icon>
							</a-tooltip>
						</template>
					</template>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'moneyAmount'">
							<a-input-number v-model:value="record.money" @blur="onBlur(2)" :min="0" :precision="2" placeholder="请输入"></a-input-number>
						</template>
						<template v-if="column.key === 'sort'">
							<a-input-number v-model:value="record.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['ledger_user_list_scale_delete']">
											<a-menu-item @click="onDelete(2, record)"> 删除 </a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
					<template #summary>
						<a-table-summary-row>
							<a-table-summary-cell>总：</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text>￥{{totalMoneyAmount}}</a-typography-text>
							</a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>

			<h4 style="margin-top: 20px;padding-left: 20px;border-left: solid 3px #169bd5;margin-bottom: 10px;font-size: 16px;">
				分账比例设置</h4>
			<a-row>
				<a-col style="width: 100px;">
					<a-button v-permission="['ledger_user_list_scale_add']" type="primary" @click="onAdd(1)">新增用户</a-button>
				</a-col>
				<a-col>
					<div style="padding-top: 4px;color: red;">注：配置参与分账用户的总分账比例需≤100%</div>
				</a-col>
			</a-row>
			<div>
				<a-table :columns="columns" :dataSource="list" :pagination="false" rowKey="id">
					<template #headerCell="{ column }">
						<template v-if="column.key === 'sort'">
							排序
							<a-tooltip>
								<template #title>
									数字越大，排序越前，优先进行分账，不允许同级
								</template>
								<Icon icon="QuestionCircleOutlined"></Icon>
							</a-tooltip>
						</template>
					</template>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key == 'moneyScale'">
							<div>
								<a-input-number @blur="onBlur(1)" class="ui-input" :min="0" :max="100" v-model:value="record.allocateRatio"></a-input-number>
								<span>%</span>
							</div>
						</template>
						<template v-if="column.key === 'sort'">
							<a-input-number v-model:value="record.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['ledger_user_list_scale_delete']">
											<a-menu-item @click="onDelete(1, record)"> 删除 </a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
					
					<template #summary>
						<a-table-summary-row>
							<a-table-summary-cell>总：</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text>{{totalMoneyScale}}%</a-typography-text>
							</a-table-summary-cell>
						</a-table-summary-row>
					</template>
      
				</a-table>
			</div>

			<a-row>
				<a-col :span="22" style="margin-top: 20px;text-align: center;">
					<a-button v-permission="['ledger_user_list_submit']" type="primary" @click="onSubmit">提交</a-button>
				</a-col>
			</a-row>
		</a-spin>
		<a-modal v-model:visible="visible" title="新增分账用户(全局)"  @cancel="onCancel" @ok="onAddOk" width="850px" height="500px">
            <a-spin :spinning="loading">
				<a-form ref="form" :model="formState" name="form" @finish="onSearch">
					<a-row>
						<a-form-item class="ui-form__item" label="企业/个人用户信息" name="userInfo">
							<a-input placeholder="手机号/姓名/昵称" v-model:value="formState.userInfo"></a-input>
						</a-form-item>

						<a-form-item class="ui-form__item" label="用户类型" name="type">
							<a-select placeholder="请选择" v-model:value="formState.type" style="width: 180px;">
								<a-select-option :value="1">个人用户</a-select-option>
								<a-select-option :value="2">企业用户</a-select-option>
							</a-select>
						</a-form-item>
						<a-col style="text-align: right;">
							<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="onReset">重置</a-button>
						</a-col>
					</a-row>
				</a-form>

				<div style="margin-top: 20px;">
					<a-table :pagination="pagination" :columns="userColumns" :dataSource="userList" :scroll="{ x: 600 }"  tableLayout="auto"
					:rowSelection="{selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll}" rowKey="id">
						<template #bodyCell="{ column, record, index }">
							<template v-if="column.key === 'type'">
								<div v-if="record.type == 1">个人</div>
								<div v-else>企业</div>
							</template>

							<template v-if="column.key === 'useType'">
								<div>{{transUserType(record.useType)}}</div>
							</template>

							<template v-if="column.key === 'corpName'">
								<div v-if="record.corpName">{{record.corpName}}</div>
								<div v-else>--</div>
							</template>
							
							<template v-if="column.key === 'identified'">
								<div v-if="record.identified == 'Y'">是</div>
								<div v-else>否</div>
							</template>

						</template>
					</a-table>
				</div>
			</a-spin>
        </a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
    import {getLedgerUserList,updateScale,ledgerGroupConfigList,saveLedgerGroupUser,deleteLedgerGroupUser,updateLedgerGroupUserScale} from '@/service/modules/ledger.js';
	export default {
		components: {
            Icon,
			Header
        },
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				list: [],
				userList: [],
				formState: {},
				columns: [{
					title: '用户',
					dataIndex: 'userName',
				}, {
					title: '分账比例',
					dataIndex: 'allocateRatio',
					key: 'moneyScale',
					width: '50%'
				}, {
					title: '排序',
					key: 'sort',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				userColumns: [{
					title: '用户类型',
					dataIndex: 'type',
					key: 'type'
				}, {
					title: '分账身份',
					dataIndex: 'useType',
					key: 'useType'
				}, {
					title: '企业名称',
					key: 'corpName'
				}, {
					title: '企业用户法人姓名/个人用户姓名',
					dataIndex: 'userName'
				}, {
					title: '企业用户法人手机号/个人用户手机号',
					dataIndex: 'telNo',
					key: 'telNo'
				}, {
					title: '是否实名',
					key: 'identified'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getLedgerUserList();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getLedgerUserList();
					}
				},
				amountList: [],
				amountColumns: [{
					title: '用户',
					dataIndex: 'userName',
				}, {
					title: '分账固定金额',
					key: 'moneyAmount',
					width: '50%'
				}, {
					title: '排序',
					key: 'sort',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				totalMoneyAmount: 0,
				totalMoneyScale: 0,
				visible: false,
				selectedRowKeys: [],
				type: 1,
				amountRowKeys: [],
				scaleRowKeys: []
			}
		},
		created() {
			this.getLedgerGroupConfigList();
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getLedgerUserList();
			},
			onReset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getLedgerUserList() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					useType: 2,
					identified: 'Y',
					...this.searchData,
				}
				this.loading = true;
				try{
					let res = await getLedgerUserList(postData);
					if(res.code == 200){
						this.loading = false;
						this.userList = res.data.list;
						this.pagination.total = res.data.totalCount;
					}
				}catch(e){
					this.loading = false;
				}
			},
			// 分账组用户配置列表
			async getLedgerGroupConfigList() {
                this.spinning = true;
				this.amountRowKeys = [];
				this.scaleRowKeys = [];
				let totalMoneyScale = 0;
				let totalMoneyAmount = 0;
                try {
					let {data} = await ledgerGroupConfigList({allocateGroupId: this.id});
					this.spinning = false;
					this.list = data.list;
					this.amountList = data.moneyList;
					data.list.forEach(item=>{
						totalMoneyScale += Number(item.allocateRatio);
						this.scaleRowKeys.push(item.adapayMemberId);
					})
					this.totalMoneyScale = totalMoneyScale;
					data.moneyList.forEach(item => {
						totalMoneyAmount += Number(item.money);
						this.amountRowKeys.push(item.adapayMemberId);
					})
					this.totalMoneyAmount = totalMoneyAmount.toFixed(2);
				} catch (error) {
					this.spinning = false;
				}
            },
			//修改分账用户比例
			async updateScale(){
                const allocateGroupMemberList = [];
                let sorts = [];
                let hasSame = false;
				this.list.forEach(item=>{
					if (sorts.includes(item.sort)) {
						hasSame = true;
					}
					sorts.push(item.sort);
					allocateGroupMemberList.push({
						id: item.id,
						allocateRatio: item.allocateRatio || 0,
						sort: item.sort,
						type: 1
					})
				})
				if (hasSame) {
					return this.$message.warn('分账账户中含有相同的排序号，无法提交');
				}
				sorts = [];
				this.amountList.forEach(item => {
					if (sorts.includes(item.sort)) {
						hasSame = true;
					}
					sorts.push(item.sort);
					allocateGroupMemberList.push({
						id: item.id,
						money: item.money || 0,
						sort: item.sort,
						type: 2
					})
				})
				if (hasSame) {
					return this.$message.warn('分账账户中含有相同的排序号，无法提交');

				}
				this.loading = true;
				try {
					let res = await updateLedgerGroupUserScale({
						allocateGroupMemberList
					});
					this.loading = false;
					if(res.code == 200){
						this.$message.success('修改成功');
					}
				} catch (error) {
					this.loading = false;
					this.$message.error('修改失败');
				}
			},
			onBlur(type){
				if (type === 1) {
					this.totalMoneyScale = 0;
					this.list.forEach(item=>{
						this.totalMoneyScale += Number(item.allocateRatio);
					})
				}
				if (type === 2) {
					let totalMoneyAmount = 0;
					this.amountList.forEach(item=>{
						totalMoneyAmount += Number(item.money);
					})
					this.totalMoneyAmount = totalMoneyAmount.toFixed(2);
				}
			},
			onSubmit(){
				if(this.totalMoneyScale > 100){
					this.$message.warn('配置参与分账用户的总分账比例不能大于100%');
					return;
				}
				this.updateScale();
			},
			async onDelete(type, val){
				try {
					let res = await deleteLedgerGroupUser({ id: val.id });
					if(res.code == 200){
						this.getLedgerGroupConfigList();
						this.$message.success('删除成功');
					}
				} catch (error) {
					this.$message.error('删除失败');
				}
			},
			onAdd(type) {
				this.type = type;
				if (type === 2) {
					this.selectedRowKeys = this.amountRowKeys;
				}
				if (type === 1) {
					this.selectedRowKeys = this.scaleRowKeys;
				}
				console.log(type)
				console.log(this.selectedRowKeys)
				this.getLedgerUserList();
				this.visible = true;
			},
			async onAddOk(){
				const ids = [];
				const userIds = [];
				if (this.type === 1) {
					this.list.forEach(item=>{
						userIds.push(item.adapayMemberId);
					})
				}
				if (this.type === 2) {
					this.amountList.forEach(item=>{
						userIds.push(item.adapayMemberId);
					})
				}
				this.selectedRowKeys.forEach(item=>{
					if(userIds.indexOf(item) == -1) {
						ids.push(item);
					};
				})
				if (this.type === 1) {
					this.scaleRowKeys = JSON.parse(JSON.stringify(this.selectedRowKeys));
				}
				if (this.type === 2) {
					this.amountRowKeys = JSON.parse(JSON.stringify(this.selectedRowKeys));
				}
				if(!ids[0]) {
					this.visible = false;
					return;
				}
				const postData = {
					adapayMemberIds: ids.join(','),
					allocateGroupId: this.id,
					type: this.type
				}
				try {
					let res = await saveLedgerGroupUser(postData);
					if(res.code == 200){
						this.getLedgerGroupConfigList();
						this.$message.success('新增成功');
						this.visible = false;
						this.$refs.form.resetFields();
					}
				} catch (error) {
					this.$message.error('新增失败');
				}
			},
			transUserType(type){
				const typeObj = {
					1: '分销',
					2: '全局',
					3: '影院'
				}
				return typeObj[type];
			},
			onSelectChange(record, selected) {
				if (selected) {
					// this.selectedListData.push(JSON.parse(JSON.stringify(record)));
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					//this.selectedListData = this.selectedListData.filter(item => {
					//	return item.id !== record.id;
					//});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.userList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							// this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.userList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							// this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onCancel() {
				this.visible = false;
				this.$refs.form.resetFields();
				const selectedRowKeys = [];
				this.spinning = false;
				this.list.forEach(item=>{
					selectedRowKeys.push(item.adapayMemberId);
				})
				this.selectedRowKeys = JSON.parse(JSON.stringify(selectedRowKeys))
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-input{
		width: 30%;
		display: inline-block;
		margin-right: 10px;
	}
	.ui-tips{
		color: #ff0000;
		margin-top: 30px;
	}
	
</style>
