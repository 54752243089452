<template>
	<div v-if="!congfigureVisible">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="分账组名称" name="title">
						<a-input placeholder="请输入" v-model:value="formState.title"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="是否为默认分账组" name="isDefault">
						<a-select placeholder="请选择" v-model:value="formState.isDefault" style="width: 180px;" >
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['ledger_user_list_add']" type="primary" @click="onAdd">新增</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"  tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key == 'isDefault'">
							<div>{{['否','是'][record.isDefault]}} </div>
						</template>
						
						<template v-if="column.key == 'createTime'">
							<div>{{transDateTime(record.createTime)}} </div>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['ledger_user_list_configure']">
											<a-menu-item @click="onDetail(record)"> 配置分账用户 </a-menu-item>
										</div>
										<!-- <div v-permission="['ledger_user_list_default']" v-if="record.isDefault != 1">
											<a-menu-item @click="onDefault(record)"> 设置为默认组 </a-menu-item>
										</div> -->
										<div v-permission="['ledger_user_list_edit']">
											<a-menu-item @click="onEdit(record)"> 编辑 </a-menu-item>
										</div>
										<div v-permission="['ledger_user_list_delete']" v-if="record.isDefault == 0">
											<a-menu-item @click="onDelete(record)"> 删除 </a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<a-modal v-model:visible="addVisible" :title="isEdit ? '编辑分账组' : '新增分账组'" @ok="onAddOk" @cancel="onCancel">
            <a-spin :spinning="loading">
                <a-form :model="addData">
                    <a-form-item label="分账组名称" :rules="[{required: true, message: '必填项不能为空'}]">
                        <a-input v-model:value="addData.title" placeholder="请输入分账组名称"></a-input>
                    </a-form-item>
					<!-- <a-form-item label="是否为默认组">
						<a-radio-group v-model:value="addData.isDefault">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
                    </a-form-item> -->
                </a-form>
            </a-spin>
        </a-modal>
	</div>
	<configure v-else :id="id" @back="onBack"></configure>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
    import {ledgerGroupList,saveLedgerGroup,updateLedgerGroup,deleteLedgerGroup} from '@/service/modules/ledger.js';
	import configure from './configure.vue';
	export default {
		components: {
            Icon,
			configure
        },
		data() {
			return {
				loading: false,
				formState: {},
				list: [],
				columns: [{
					title: '分帐组名称',
					dataIndex: 'title',
				}, {
					title: '是否为默认分账组',
					key: 'isDefault',
				}, {
					title: '创建时间',
					key: 'createTime',
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				addVisible: false,
				addData: {},
				isEdit: false,
				congfigureVisible: false,
				id: 0
			}
		},
		created() {
            this.getData();
		},
		methods: {
			onBack() {
				this.congfigureVisible = false;
				this.getData();
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
                try {
					let res = await ledgerGroupList(postData);
					if(res.code == 200){
						this.loading = false;
						this.list = res.data.list;
						this.pagination.total = res.data.totalCount;
					}
				} catch (error) {
					this.loading = false;
				}
            },
			onAdd(){
				this.addVisible = true;
			},
			onAddOk(){
				if(!this.addData.title) {
					this.$message.warn('请输入分账组名称');
					return;
				}
				if(this.isEdit){
					this.updateLedgerGroup();
				}else{
					this.saveLedgerGroup();
				}
				
			},
			onCancel(){
				this.addData = {};
				this.addVisible = false;
			},
			onEdit(val){
				this.addVisible = true;
				this.isEdit = true;
				this.list.forEach(item=>{
					if(item.id == val.id){
						this.addData = {
							id: item.id,
							title: item.title
						};
					}
				});
			},
			onDelete(val){
				if(val.isDefault == 1) return;
				this.$confirm({
					title: 'warning',
					content: '正在进行删除操作',
					okText: '确定',
					cancleText: '取消',
					onOk: async () => {
						try {
							let res = await deleteLedgerGroup({id: val.id});
							if(res.code == 200){
								this.getData();
								this.$message.success('删除成功');
							}
						} catch (error) {
							this.$message.error('删除失败');
						}
					}
				})
			},
			onDefault(val){
				this.$confirm({
					title: 'warning',
					content: '正在进行设置为默认组操作',
					okText: '确定',
					cancleText: '取消',
					onOk: async () => {
						return
						let res = await setDefault({id: val.id});
						if(res.code == 200){
							this.getData();
							this.$message.success('操作成功');
						}
					}
				})
			},
			onDetail(val){
				this.congfigureVisible = true;
				this.id = val.id;
			},
			async saveLedgerGroup(){
				try {
					let res = await saveLedgerGroup({
						title: this.addData.title
					})
					if(res.code == 200){
						this.getData();
						this.addData = {};
						this.$message.success('新增成功');
						this.addVisible = false;
					}
				} catch (error) {
					this.$message.error('新增失败');
				}
			},
			async updateLedgerGroup(){
				try {
					let res = await updateLedgerGroup({
						id: this.addData.id,
						title: this.addData.title
					})
					if(res.code == 200){
						this.getData();
						this.addData = {};
						this.$message.success('修改成功');
						this.addVisible = false;
						this.isEdit = false;
					}
				} catch (error) {
					this.$message.error('修改失败');
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
